import styled from 'styled-components'

export const FlexX = styled.div`
    display: flex;
    justify-content: center;
`

export const FlexY = styled.div`
    display: flex;
    flex-direction: column;
`

export const Title = styled.div`
    font-size: 54px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 38px;
`

export const Subtitle = styled.div`
    color: #007CEB;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
`

const Button = styled.button<{variant?: string, size?: string}>`
    background: ${({ variant }) => variant === 'primary' ? 'linear-gradient(90deg, #0368FF 0%, #00A1FE 100%)' : 'transparent'};
    padding: ${({ size }) => size === 'md' ? '10px 50px' : '10px 66px'};
    border: ${({ variant }) => variant === 'primary' ? 'none' : '1px solid #FFFFFF'};
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
        opacity: 0.65;
    }
`
Button.defaultProps = {
    variant: 'primary',
    size: 'md'
}

export { Button }