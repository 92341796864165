import styled from 'styled-components'
import { Title } from 'components/Style'

const BoringWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 65px auto 150px;
    ${({ theme }) => theme.mediaQueries.md} {
        margin: 65px auto 250px;
    }
`
const StyleTitle = styled(Title)`
    font-size: 35px;
    margin-bottom: 100px;
    ${({ theme }) => theme.mediaQueries.md} {
        font-size: 54px;
    }
`
const Grid = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 30px;
    ${({ theme }) => theme.mediaQueries.md} {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 100px;
    }
`
const Flex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
        margin-bottom: 15px;
        width: 80px;
        height: 80px;
        ${({ theme }) => theme.mediaQueries.md} {
            width: 120px;
            height: 120px;
            margin-bottom: 30px;
        }
    }
`
const Text = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    ${({ theme }) => theme.mediaQueries.md} {
        font-size: 36px;
        margin-bottom: 20px;
    }
`
const Subtext = styled.div`
    font-size: 14px;
    line-height: 18px;
    color: #007CEB;
    ${({ theme }) => theme.mediaQueries.md} {
        font-size: 18px;
        line-height: 29px;
    }
`


const BoringDAO = () => {
    return (
        <BoringWrapper>
            <StyleTitle>Why BoringDAO?</StyleTitle>
            <Grid>
                <Flex>
                    <img src="./images/ic_lowcost.png" width="120px" height="120px" alt="lowcost" />
                    <Text>Low Cost</Text>
                    <Subtext>Competitive fee</Subtext>
                    <Subtext>Flexible rate</Subtext>
                </Flex>
                <Flex>
                    <img src="./images/ic_fast.png" width="120px" height="120px" alt="lowcost" />
                    <Text>Fast</Text>
                    <Subtext>Fast confirmation</Subtext>
                    <Subtext>Instant transfer</Subtext>
                </Flex>
                <Flex>
                    <img src="./images/ic_secure.png" width="120px" height="120px" alt="lowcost" />
                    <Text>Secure</Text>
                    <Subtext>Open source</Subtext>
                    <Subtext>0 accident</Subtext>
                    <Subtext>5 code audits</Subtext>
                </Flex>
                <Flex>
                    <img src="./images/ic_permissionless.png" width="120px" height="120px" alt="lowcost" />
                    <Text>Permissionless</Text>
                    <Subtext>Open for integration</Subtext>
                    <Subtext>Run your own nodes</Subtext>
                    <Subtext>NEL technology</Subtext>
                </Flex>
                <Flex>
                    <img src="./images/ic_decentralized.png" width="120px" height="120px" alt="lowcost" />
                    <Text>Decentralized</Text>
                    <Subtext>Non-custodian</Subtext>
                    <Subtext>Blockchain as the server</Subtext>
                </Flex>
                <Flex>
                    <img src="./images/ic_dao.png" width="120px" height="120px" alt="lowcost" />
                    <Text>DAO Governance</Text>
                    <Subtext>Earnings sharing</Subtext>
                    <Subtext>Community driven</Subtext>
                </Flex>
            </Grid>
        </BoringWrapper>
    )
}

export default BoringDAO