import React from 'react'
import styled from 'styled-components'
import { Subtitle, FlexX } from 'components/Style'
import { ECO, Audit, Exchange } from './config'
const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
const Box = styled.div`
    width: 100%;
    display: none;
    ${({ theme }) => theme.mediaQueries.sm} {
        display: block;
    }
`
const Grid = styled.div`
    display: none;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 56px;
    width: 720px;
    margin: 0 auto;
    justify-content: center;
    ${({ theme }) => theme.mediaQueries.sm} {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        width: 600px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-row-gap: 72px;
        width: 720px;
    }
`
const Grid6 = styled(Grid)`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    ${({ theme }) => theme.mediaQueries.sm} {
        display: none;
    }
`
const Flex6 = styled.div`
    display: none;
    justify-content: center;
    > img {
        margin: 0 22px;
    }
    ${({ theme }) => theme.mediaQueries.sm} {
        display: flex;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        display: none;
    }
`
const Flex7 = styled.div`
    display: none;
    justify-content: center;
    > img {
        margin: 0 22px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        display: flex;
    }
`
const FlexMobile = styled.div`
    display: flex;
    padding: 12px 20px 30px;
    flex-wrap: wrap;
    justify-content: center;
    > div {
        margin: 20px;
    }
    ${({ theme }) => theme.mediaQueries.xs} {
        flex: 0 0 25%;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        display: none;
    }
`
const Flex = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    > img {
        margin: 20px 22px;
    }
    ${({ theme }) => theme.mediaQueries.sm} {
        > img {
            margin: 20px 22px;
        }
    }
`
const ExchangeFlex = styled(Flex)`
    width: auto;
    ${({ theme }) => theme.mediaQueries.sm} {
       width: 500px;
    }
`
interface Props {
    title: string
}

export const ExchangeImageList: React.FC<Props> = ({ title }) => {
    return(
        <ImageWrapper>
            <Subtitle>{title}</Subtitle>
            <ExchangeFlex style={{margin: '50px auto 55px'}}>
                {Exchange.map(d => (<img key={d} src={`./images/Exchanges/${d}`} width="36px" height="36px" alt={d} />))}
            </ExchangeFlex>
        </ImageWrapper>
    )
}

export const AuditImageList: React.FC<Props> = ({ title }) => {
    return(
        <ImageWrapper>
            <Subtitle>{title}</Subtitle>
            <Flex style={{margin: '50px auto 55px'}}>
                {Audit.map(d => (<img key={d} src={`./images/Audit/${d}`} width="36px" height="36px" alt={d} />))}
            </Flex>
        </ImageWrapper>
    )
}

const EcoImageList: React.FC<Props> = ({ title }) => {
    return(
        <ImageWrapper>
            <Subtitle>{title}</Subtitle>
            <Box>
                <Grid style={{marginTop: '50px'}}>
                    {ECO.slice(0, ECO.length - 10).map(d => (<FlexX key={d}><img src={`./images/ECO/${d}`} width="36px" height="36px" alt={d} /></FlexX>))}
                </Grid>
                <Grid6 style={{marginTop: '50px'}}>
                    {ECO.slice(0, ECO.length - 9).map(d => (<FlexX key={d}><img src={`./images/ECO/${d}`} width="36px" height="36px" alt={d} /></FlexX>))}
                </Grid6>
                <Flex7 style={{margin: '72px auto'}}>
                    {ECO.slice(-10, -3).map(d => (<img key={d} src={`./images/ECO/${d}`} width="36px" height="36px" alt={d} />))}
                </Flex7>
                <Flex6 style={{margin: '72px auto'}}>
                    {ECO.slice(-9, -3).map(d => (<img key={d} src={`./images/ECO/${d}`} width="36px" height="36px" alt={d} />))}
                </Flex6>
                <Flex style={{margin: '0 auto 72px'}}>
                    {ECO.slice(-3).map(d => (<img key={d} src={`./images/ECO/${d}`} width="36px" height="36px" alt={d} />))}
                </Flex>
            </Box>
            <FlexMobile>
                {ECO.map(d => (<FlexX key={d}><img src={`./images/ECO/${d}`} width="36px" height="36px" alt={d} /></FlexX>))}
            </FlexMobile>
        </ImageWrapper>
    )
}

export default EcoImageList