import React from 'react';
import { ThemeProvider } from 'styled-components'
import Header from 'components/Header'
import Banner from 'components/Banner'
import OPortal from 'components/OPortal'
import OTunnel from 'components/OTunnel'
import BoringDAO from 'components/BoringDAO'
import Chains from 'components/Chains'
import Partner from 'components/Partner'
import EcoImageList, { AuditImageList, ExchangeImageList } from 'components/ImageList'
import Footer from 'components/Footer'
import { Title } from 'components/Style'
import theme from 'theme'

const ThemeProviderWrapper = (props: any) => {
  return <ThemeProvider theme={theme} {...props} />
}

function App() {
  return (
    <ThemeProviderWrapper theme={theme}>
      <Header />
      <Banner />
      <OPortal />
      <OTunnel />
      <BoringDAO />
      <Chains />
      <Partner />
      <Title>Ecosystem</Title>
      <EcoImageList title='Eco' />
      <AuditImageList title="Audit" />
      <ExchangeImageList title="Exchange" />
      <Footer />
    </ThemeProviderWrapper>
  );
}

export default App;
