import styled from 'styled-components'

const ChainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    ${({ theme }) => theme.mediaQueries.md} {
        padding-bottom: 150px;
    }
`
const Chainbox = styled.div`
    position: relative;
    width: 280px;
    height: auto;
    margin-bottom: 130px;
    ${({ theme }) => theme.mediaQueries.sm} {
        width: 450px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        width: 685px;
    }
`
const Chainnumber = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
    > span:nth-of-type(1) {
        font-size: 70px;
        line-height: 60px;
        font-weight: 600;
        ${({ theme }) => theme.mediaQueries.sm} {
            font-size: 140px;
            line-height: 160px;
        }
        ${({ theme }) => theme.mediaQueries.md} {
            font-size: 210px;
            line-height: 180px;
        }
    }
    > span:nth-of-type(2) {
        font-size: 12px;
        ${({ theme }) => theme.mediaQueries.sm} {
            font-size: 24px;
        }
        ${({ theme }) => theme.mediaQueries.md} {
            font-size: 36px;
        }
    }
    
`
const Coinbox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        width: 300px;
        height: auto;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        img {
            width: 410px;
            height: auto;
        }
    }
`
const Text = styled.div`
    color: #007CEB;
    font-size: 18px;
    margin-top: 20px;
    ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 24px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        font-size: 36px;
        margin-top: 30px;
    }
`

const Chains = () => {
    return (
        <ChainWrapper>
            <Chainbox>
                <img src="./images/bg_chains.png" alt="chains" width="100%" height="auto" />
                <Chainnumber>
                    <span>15</span>
                    <span>Connected Chains</span>
                </Chainnumber>
            </Chainbox>
            <Coinbox>
                <img src="./images/loyal.png" alt="btc"  />
                <Text>We are loyal to BTC, LTC, DOGE</Text>
            </Coinbox>
        </ChainWrapper>
    )
}

export default Chains