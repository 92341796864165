import styled from 'styled-components'
import { Button } from 'components/Style'

const OportalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 105px;
`
const PortalImg = styled.img`
    width: 204px;
    height: 57px;
    ${({ theme }) => theme.mediaQueries.md} {
        width: 340px;
        height: 95px;
    }
`
const Introduce = styled.div`
    position: relative;
    margin: 50px 0 62px;
    width: 100%;
`
const Flex = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
`
const Text = styled.div`
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 20px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        font-size: 36px;
    }
`
const LearnMore = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    cursor: pointer;
    &:after {
        content: '';
        display: block;
        margin-top: 2px;
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #D8D8D8;
        border: 1px solid #979797;
    }
`

const Oportal = () => {
    return (
        <OportalWrapper>
            <PortalImg src='./images/oportal.png' width="339px" height="95px" alt="oPortal" />
            <Introduce>
                <img src='./images/bg_oportal.png' width="100%" alt="oPortal" />
                <Flex>
                    <Text>Enable assets to be freely transferred</Text>
                    <Text>between different chains,</Text>
                    <Text>in a cost efficient and timely manner.</Text>
                </Flex>
            </Introduce>
            <a target="_blank" href="https://oportal.boringdao.com/twowayhttps:/oportal.boringdao.com/twoway">
                <Button style={{marginBottom: '78px'}}>Launch oPortal</Button>
            </a>
            <Text style={{marginBottom: '28px'}}>We build exclusive bridge for any kind of assets</Text>
            <a target="_blank" href="https://forms.gle/sAtKswt419FUEctN7">
                <Button variant='outlined' style={{marginBottom: '30px'}}>List your coin now</Button>
            </a>
            <a target="_blank" href="https://docs.boringdao.com/"><LearnMore>Learn More</LearnMore></a>
        </OportalWrapper>
    )
}

export default Oportal