export const ECO =
    [
        'curve.png',
        'sushi.png',
        'polygon.png',
        'metis.png',
        'bitpie.png',
        '88mph.png',
        'ACoconut.png',
        'alpaca.png',
        'yfi.png',
        'bancor.png',
        'alphafinance.png',
        'ankr.png',
        'Arbitrum.png',
        'aurora.png',
        'autofarm.png',
        'avax.png',
        'badger.png',
        'beefy finance.png',
        'bitkeep.png',
        'boba.png',
        'bounce.png',
        'bsc.png',
        'chainlink.png',
        'clover.png',
        'convex.png',
        'debank.png',
        'defillama.png',
        'definer.png',
        'defipulse.png',
        'Dodo.png',
        'easyfi.png',
        'footprint.png', 
        'fortube.png',
        'harmony.png',
        'harvest.png',
        'hashquark.png',
        'heco.png',
        'immunefi.png',
        'imtoken.png',
        'Izumi.png',
        'loopring.png',
        'LTC foundation.png',
        'mathwallet.png',
        'meli-games.png',
        'metadoge.png',
        'netswap.png',
        'Nsure.png',
        'oasis.png',
        'OEC.png',
        'Oolongswap.png',
        'op.png',
        'pancake.png',
        'placewar.png',
        'quickswap.png',
        'stratos.png',
        'sunder.png',
        'tokenpocket.png',
        'umbrella.png',
        'bsc project.png',
        'bsc tools.png',
        'unit protocol new.png',
        'wault.png',
        'xdai.png',
    ]

export const Audit = ['certik.png', 'peckshield.png']

export const Exchange = 
['okex.png', 'huobi.png', 'gate.png', 'bitmart.png', 'bancor.png',
'mexc.png', 'bitrue.png', 'uniswap.png', 'Dodo.png', 'pancake.png']