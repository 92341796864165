import styled from 'styled-components'
import { Button, FlexX, Title } from 'components/Style'

const OTunnelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    background: url('./images/otunnel.png') no-repeat;
    background-size: 100% 100%;
    padding: 85px 0 105px;
`

const Text = styled.div`
    color: #007CEB;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    ${({ theme }) => theme.mediaQueries.md} {
        font-size: 36px;
    }
`
const SubText = styled(Text)`
    font-size: 12px;
    margin-top: -10px;
    ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 20px;
        margin-top: -10px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        font-size: 28px;
        margin-top: -14px;
    }
`
const TokenBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 73px 0 110px;
`
const TokenImg = styled.div`
    display: flex;
    flex-direction: column;
    > img {
        width: 110px;
        height: 110px;
    }
    ${({ theme }) => theme.mediaQueries.sm} {
        > img {
            width: 150px;
            height: 150px;
        }
    }
    ${({ theme }) => theme.mediaQueries.md} {
        > img {
            width: 250px;
            height: 250px;
        }
    }
`

const OTunnel = () => {
    return (
        <OTunnelWrapper>
            <Title>oTunnel</Title>
            <Text>The safest way to wrap your assets.</Text>
            <TokenBox>
                <TokenImg>
                    <img src="./images/obtc.png" width="250px" height="250px" alt="BTC" />
                    <SubText>oBTC</SubText>
                </TokenImg>
                <TokenImg style={{margin: '0 -20px'}}>
                    <img src="./images/oltc.png" width="250px" height="250px" alt="BTC" />
                    <SubText>oLTC</SubText>
                </TokenImg>
                <TokenImg>
                    <img src="./images/odoge.png" width="250px" height="250px" alt="BTC" />
                    <SubText>oDOGE</SubText>
                </TokenImg>
            </TokenBox>
            <FlexX>
                <a target="_blank" href="https://app.boringdao.com/pledge/BTC">
                    <Button>Launch oTunnel</Button>
                </a>
            </FlexX>
        </OTunnelWrapper>
    )
}

export default OTunnel