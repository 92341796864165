import styled from 'styled-components'
import { Title } from 'components/Style'

const PartnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 95px;
`
const StyleTitle = styled(Title)`
    font-size: 36px;
    ${({ theme }) => theme.mediaQueries.md} {
        font-size: 54px;
    }
`
const PartnerList = styled.div`
    display: none;
    flex-direction: column;
    align-items: center;
    ${({ theme }) => theme.mediaQueries.md} {
        display: flex;
    }
`
const Grid = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    img {
        flex: 0 0 50%;
        width: 100px;
        height: auto;
    }
    ${({ theme }) => theme.mediaQueries.sm} {
        img {
            flex: 0 0 33.333333%;
        }
    }
    ${({ theme }) => theme.mediaQueries.md} {
        display: none;
    }
`
const Flex = styled.div`
    display: flex;
`
const SubFlex = styled.div`
    display: flex;
`
const Partner = () => {
    return (
        <PartnerWrapper>
            <StyleTitle>Investment Partners</StyleTitle>
            <PartnerList>
                <Flex>
                    <img src="./images/paranter/defiancecapital.png" width="200px" height="auto" alt="defiancecapital" />
                    <img src="./images/paranter/nothingresearch.png" width="200px" height="auto" alt="nothingresearch" />
                    <img src="./images/paranter/Hashkey.png" width="200px" height="auto" alt="Hashkey" />
                    <img src="./images/paranter/youbi.png" width="200px" height="auto" alt="youbi" />
                </Flex>
                <Flex>
                    <img src="./images/paranter/SNZ.png" width="200px" height="auto" alt="SNZ" />
                    <img src="./images/paranter/snapfiners.png" width="200px" height="auto" alt="snapfiners" />
                    <img src="./images/paranter/ALT.png" width="200px" height="auto" alt="ALT" />
                </Flex>
                <Flex>
                    <img src="./images/paranter/puzzleventures.png" width="200px" height="auto" alt="puzzleventures" />
                    <img src="./images/paranter/bilian.png" width="200px" height="auto" alt="bilian" />
                    <img src="./images/paranter/fundamentallabs.png" width="200px" height="auto" alt="fundamentallabs" />
                    <img src="./images/paranter/digitalrenaissance.png" width="200px" height="auto" alt="digitalrenaissance" />
                </Flex>
            </PartnerList>
            <Grid>
                <img src="./images/paranter/defiancecapital.png" alt="defiancecapital" />
                <img src="./images/paranter/nothingresearch.png" alt="nothingresearch" />
                <img src="./images/paranter/Hashkey.png" alt="Hashkey" />
                <img src="./images/paranter/youbi.png" alt="youbi" />
                <img src="./images/paranter/SNZ.png" alt="SNZ" />
                <img src="./images/paranter/snapfiners.png" alt="snapfiners" />
                <img src="./images/paranter/ALT.png" alt="ALT" />
                <img src="./images/paranter/puzzleventures.png" alt="puzzleventures" />
                <img src="./images/paranter/bilian.png" alt="bilian" />
                <img src="./images/paranter/fundamentallabs.png" alt="fundamentallabs" />
                <img src="./images/paranter/digitalrenaissance.png" alt="digitalrenaissance" />
            </Grid>
        </PartnerWrapper>
    )
}

export default Partner