import styled from 'styled-components'

import logo from 'assets/images/logo.svg'
import Twitter from 'assets/images/twitter.svg'
import Telegram from 'assets/images/telegram.svg'
import Discord from 'assets/images/discord.svg'
import Github from 'assets/images/github.svg'
import Medium from 'assets/images/medium.svg'
import Forum from 'assets/images/forum.svg'
import Doc from 'assets/images/doc.svg'

const HeaderWrapper = styled.header`
    display: flex;
    justify-content: space-between;
    padding: 18px;
    background: transparent;
    img {
        z-index: 1;
    }
`
const ChatBox = styled.div`
    display: flex;
    align-items: center;
    a {
        display: flex;
    }
    a:not(:last-child) {
        margin-right: 20px;
    }
`

const Header = () => {
    return (
        <HeaderWrapper>
            <img src={logo} alt="logo" width="98px" height="24px" />
            <ChatBox>
                <a target="_blank" href='https://github.com/BoringDAO'><img src={Github} alt="Github" width="16px" height="16px" /></a>
                <a target="_blank" href='https://docs.boringdao.com'><img src={Doc} alt="Github" width="20px" height="18px" /></a>
                <a target="_blank" href='https://twitter.com/TheBoringDAO'><img src={Twitter} alt="Twitter" width="16px" height="13px" /></a>
                <a target="_blank" href="https://discord.com/invite/zCQ2ddp"><img src={Discord} alt="Discord" width="16px" height="16px" /></a>
                <a target="_blank" href="https://t.me/boringdao_official"><img src={Telegram} alt="Telegram" width="16px" height="16px" /></a>
                <a target="_blank" href="https://theboringdao.medium.com/"><img src={Medium} alt="Medium" width="16px" height="10px"/></a>
                <a target="_blank" href="https://forum.boringdao.com/"><img src={Forum} alt="Forum" width="16px" height="16px" /></a>
            </ChatBox>
        </HeaderWrapper>
    )
}

export default Header