import styled from 'styled-components'
import { Button, FlexX } from 'components/Style'
import AggregatorIcon from '../../assets/images/aggregator.png'
const BanenrWrapper = styled.div`
    margin: -60px auto 40%;
    position: relative;
    min-height: 450px;
    ${({ theme }) => theme.mediaQueries.md} {
        margin: -60px auto 0;
        min-height: 600px;
    }
`
const BannerBox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 60%;
    width: 100%;
    ${({ theme }) => theme.mediaQueries.sm} {
        padding-top: 30%;
        width: auto;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        padding-top: 0;
        width: auto;
    }
`
const Video = styled.video`
    width: 100%;
    margin-top: 0;
    ${({ theme }) => theme.mediaQueries.sm} {
        margin-top: -24.2%;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        margin-top: -28.2%;
    }
    ${({ theme }) => theme.mediaQueries.lg} {
        margin-top: -30%;
    }
    ${({ theme }) => theme.mediaQueries.xl} {
        width: 80%;
        margin: -18% auto 0;
    }
`
const Gif = styled.img`
    width: 100%;
    margin-top: 0;
    ${({ theme }) => theme.mediaQueries.sm} {
        margin-top: -24.2%;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        margin-top: -28.2%;
    }
    ${({ theme }) => theme.mediaQueries.lg} {
        margin-top: -30%;
    }
    ${({ theme }) => theme.mediaQueries.xl} {
        width: 80%;
        margin: -18% auto 0;
    }
`
const Solgo = styled.div`
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    font-size: 30px;
    ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 30px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        font-size: 35px;
    }
    ${({ theme }) => theme.mediaQueries.lg} {
        font-size: 56px;
    }
`
const SubSolgo = styled.div`
    color: #007CEB;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 28px auto 28px;
    padding: 0 20%;
    ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 24px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        font-size: 29px;
        padding: 0;
        margin: 42px auto 35px;
    }
`
const Text = styled.div`
    font-size: 18px;
    line-height: 29px;
    color: #ffffff;
    text-align: center;
`
const StyleButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 44px;
    padding: 10px 0;
    margin-bottom: 20px;
    > img {
      margin-right: 10px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
        width: 220px;
    }
`
const Launch = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 64px;
    flex-direction: column;
    align-items: center;
    ${({ theme }) => theme.mediaQueries.md} {
        flex-direction: row;
    }
    > a {
        margin: 0 10px;
        text-decoration: none;
        ${({ theme }) => theme.mediaQueries.md} {
            margin: 0 30px;
        }
    }
`

const Banner = () => {
    return (
        <BanenrWrapper>
            <FlexX>
                <Gif src="./images/banner.png" alt="boring" />
                {/* <Gif src="./images/boring.gif" alt="boring" /> */}
                {/* <Video loop muted autoPlay>
                    <source src="https://boring.s3.ap-east-1.amazonaws.com/Ring_animation_60update.mp4" type="video/mp4" />
                </Video> */}
            </FlexX>
            <BannerBox>
                <Solgo>Multi-Chain Solutions</Solgo>
                <Solgo>for Every Crypto Asset</Solgo>
                <SubSolgo>The ultimate DAO-driven inter-blockchain hub for Web 3.0</SubSolgo>
                <Text>High security. Fast transaction. Low cost.</Text>
                <Text>All permissionless and decentralized.</Text>
                <Launch>
                    {/*<a target="_blank" href="https://aggregator.boringdao.com/">*/}
                    {/*    <StyleButton>*/}
                    {/*        <img src={AggregatorIcon} width={24} height={24} alt="aggregator"/>*/}
                    {/*        Launch Aggregator</StyleButton>*/}
                    {/*</a>*/}
                    <a target="_blank" href="https://app.boringdao.com/">
                        <StyleButton>Launch APP</StyleButton>
                    </a>
                </Launch>
            </BannerBox>
        </BanenrWrapper>
    )
}

export default Banner
